/* global Component */
class imgProxyComponent extends Component {

    static  name() {
        return "imgProxyComponent";
    }

    static componentName() {
        return "imgProxyComponent";
    }

    getProps() {
        return {
            'src': {
                type: String,
            },
            'is360': {
                type:Boolean,
                default: false,
            },
            'useZoom': {
                type:Boolean,
                default: false,
            },
            'isthumbnails': {
                type:Boolean,
                default: false,
            },
            'mystyle': {
                type: String,
                default: '',
            },'myclass': {
                type: String,
                default: '',
            },'eventclick': {
                type: Function,
            },'imgIndex' : {
                type: Number,
                dafault: 0
            }
        };
    }

    getComputed() {
        return {
            render360Component(){
                if(this.is360 && !this.isthumbnails)
                    return true;
                return false;
            },
            useZoomImage(){
                if(this.isthumbnails)
                    return false;
                return this.useZoom;
            }
        };
    }

    getTemplate() {
        return `<div>
                    <template v-if="render360Component">
                        <img360Component :src="src" :mystyle="mystyle"></img360Component>
                    </template>
                    <template v-else>
                          <template v-if="useZoomImage">
                            <imgZoomComponent myclass="item-image img-fluid" :src="src" :style="mystyle" :isthumbnails="isthumbnails"  :imgIndex="imgIndex"/>
                          </template>  
                          <template v-else>
                             <imgComponent myclass="item-image img-fluid" :src="src" :style="mystyle" :isthumbnails="isthumbnails"  :imgIndex="imgIndex"/>
                          </template>
                    </template>
             </div>`;
    }
}

imgProxyComponent.registerComponent();
